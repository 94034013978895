export const FETCH_AUTH_TOKEN = 'FETCH_AUTH_TOKEN';
export const FETCH_AUTH_TOKEN_ERROR = 'FETCH_AUTH_TOKEN_ERROR';
export const FETCH_AUTH_TOKEN_SUCCESS = 'FETCH_AUTH_TOKEN_SUCCESS';
export const FETCH_LUCKY_ORANGE = 'FETCH_LUCKY_ORANGE';
export const FETCH_LUCKY_ORANGE_ERROR = 'FETCH_LUCKY_ORANGE_ERROR';
export const FETCH_LUCKY_ORANGE_SUCCESS = 'FETCH_LUCKY_ORANGE_SUCCESS';
export const SET_SELECTED_PROVIDER_TYPE = 'SET_SELECTED_PROVIDER_TYPE';
export const FETCH_PROVIDERS = 'FETCH_PROVIDERS';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_ERROR = 'FETCH_PROVIDERS_ERROR';
export const FETCH_PROVIDER_TYPES_PRICE_SUCCESS =
	'FETCH_PROVIDER_TYPES_PRICE_SUCCESS';
export const FETCH_PROVIDER_TYPES_PRICE_FAILURE =
	'FETCH_PROVIDER_TYPES_PRICE_FAILURE';
export const FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS =
	'FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS';
export const FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_SUCCESS =
	'FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_SUCCESS';
export const FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_ERROR =
	'FETCH_CHIEF_COMPLAINT_SEARCH_RESULTS_ERROR';
export const SET_SELECTED_CHIEF_COMPLAINT = 'SET_SELECTED_CHIEF_COMPLAINT';
export const SET_SELECTED_CHIEF_COMPLAINT_COMMENT =
	'SET_SELECTED_CHIEF_COMPLAINT_COMMENT';
export const FETCH_PATIENT_BILLING_INFO = 'FETCH_PATIENT_BILLING_INFO';
export const FETCH_PATIENT_BILLING_INFO_SUCCESS =
	'FETCH_PATIENT_BILLING_INFO_SUCCESS';
export const FETCH_PATIENT_BILLING_INFO_ERROR =
	'FETCH_PATIENT_BILLING_INFO_ERROR';
export const SET_PATIENT_BILLING_INFO = 'SET_PATIENT_BILLING_INFO';
export const UPDATE_PATIENT_BILLING_INFO = 'UPDATE_PATIENT_BILLING_INFO';
export const FETCH_SPECIFIC_PROVIDER = 'FETCH_SPECIFIC_PROVIDER';
export const FETCH_SPECIFIC_PROVIDER_SUCCESS =
	'FETCH_SPECIFIC_PROVIDER_SUCCESS';
export const FETCH_SPECIFIC_PROVIDER_ERROR = 'FETCH_SPECIFIC_PROVIDER_ERROR';
export const SET_SELECTED_PROVIDER = 'SET_SELECTED_PROVIDER';
export const FETCH_PHARMACY = 'FETCH_PHARMACY';
export const FETCH_PHARMACY_SUCCESS = 'FETCH_PHARMACY_SUCCESS';
export const FETCH_PHARMACY_ERROR = 'FETCH_PHARMACY_ERROR';
export const PHARMACY_ARE_LOADED = 'PHARMACY_ARE_LOADED';
export const FETCH_MDLIVE_APPOINTMENT = 'FETCH_MDLIVE_APPOINTMENT';
export const FETCH_MDLIVE_APPOINTMENT_SUCCESS =
	'FETCH_MDLIVE_APPOINTMENT_SUCCESS';
export const FETCH_MDLIVE_APPOINTMENT_FAILURE =
	'FETCH_MDLIVE_APPOINTMENT_FAILURE';
export const SUBMIT_MDLIVE_APPOINTMENT = 'SUBMIT_MDLIVE_APPOINTMENT';
export const SUBMIT_MDLIVE_APPOINTMENT_SUCCESS =
	'SUBMIT_MDLIVE_APPOINTMENT_SUCCESS';
export const SUBMIT_MDLIVE_APPOINTMENT_FAILURE =
	'SUBMIT_MDLIVE_APPOINTMENT_FAILURE';
export const FETCH_MDLIVE_APPOINTMENT_INFORMATION =
	'FETCH_MDLIVE_APPOINTMENT_INFORMATION';
export const FETCH_MDLIVE_APPOINTMENT_INFO_SUCCESS =
	'FETCH_MDLIVE_APPOINTMENT_INFO_SUCCESS';
export const FETCH_MDLIVE_APPOINTMENT_INFO_FAILURE =
	'FETCH_MDLIVE_APPOINTMENT_INFO_FAILURE';
export const CANCEL_MDLIVE_APPOINTMENT = 'CANCEL_MDLIVE_APPOINTMENT';
export const CANCEL_MDLIVE_APPOINTMENT_SUCCESS =
	'CANCEL_MDLIVE_APPOINTMENT_SUCCESS';
export const CANCEL_MDLIVE_APPOINTMENT_FAILURE =
	'CANCEL_MDLIVE_APPOINTMENT_FAILURE';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_USER_ID = 'SET_USER_ID';
export const FETCH_APPOINTMENT_COST = 'FETCH_APPOINTMENT_COST';
export const SUBMIT_IMAGE_UPLOAD = 'SUBMIT_IMAGE_UPLOAD';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAILURE = 'IMAGE_UPLOAD_FAILURE';
